<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input
            allowClear
            v-model="searchParams.productName"
            size='small'
            style="width:150px;margin:5px;"
            placeholder='标题or商品Id'
        ></a-input>
        <a-input
            allowClear
            v-model="searchParams.userName"
            size='small'
            class="w-200 ml-5 mb-5"
            placeholder='发布者OrUserId'
        ></a-input>

        <a-select
            allowClear
            autocomplete="off"
            v-model="searchParams.reviewAdminId"
            :filter-option="untils.filterOption"
            @search="searchUser"
            showSearch
            size='small'
            class="w-200 ml-5 mb-5"
            placeholder='查询审核人'
        >
          <a-select-option
              v-for="item of userDataList"
              :key="item.id"
              :value="item.id"
          >{{ item.userName + `【${item.id}】` }}</a-select-option>
        </a-select>

        <a-select
            allowClear
            autocomplete="off"
            v-model="searchParams.onShelf"
            size='small'
            class="w-100 ml-5 mb-5"
            placeholder='上下架'
        >
          <a-select-option :value='1'>上架</a-select-option>
          <a-select-option :value='0'>下架</a-select-option>
        </a-select>


        <a-select
            allowClear
            v-model="searchParams.reviewStatus"
            size='small'
            class="ml-5 mb-5 w-150"
            placeholder='审核'
        >
          <a-select-option
              v-for="item in fixedPriceCheckStatusList"
              :key="item.value"
              :value='item.value'
          >{{ item.name }}</a-select-option>
        </a-select>
        <a-input
            allowClear
            v-model="searchParams.productCode"
            size='small'
            class="ml-5 mb-5 w-150"
            placeholder='评级编号'
        ></a-input>
        <a-select
            allowClear
            v-model="searchParams.productRatingCompany"
            size='small'
            class="ml-5 mb-5 w-200"
            placeholder='评级公司'
        >
          <a-select-option
              v-for="item in ratingCompanyTypeList"
              :key="item.value"
              :value='item.value'
          >{{ item.name }}</a-select-option>
        </a-select>
        <a-range-picker

            :placeholder="['开始日期','结束日期']"
            size='small'
            style="width:200px;margin:5px;"
            @change="searchPickerChange"
        />
        <a-input
            v-model="searchParams.coinKindName"
            size='small'
            style="width:150px;margin:5px;"
            placeholder='大币种'
        ></a-input>
        <a-select
            allowClear
            autocomplete="off"
            v-model="searchParams.coinCategoryId"
            size='small'
            class="w-200 ml-5 mb-5"
            placeholder='审核类型'
        >
          <a-select-option
              v-for="item of bigCoinCategoryList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinCategoryName }}</a-select-option>
        </a-select>
        <a-button
            @click="search"
            size='small'
            type="primary"
            style="margin-left:10px;"
        >搜索</a-button>
      </div>
    </div>
    <div class="table-content">
      <a-table
          @change="changePage"
          :pagination='pagination'
          :loading='loading'
          :columns="columns"
          :data-source="data"
          :rowKey="(record,n)=>n"
      >
        <div
            slot="idSlot"
            slot-scope="row"
        >
          <div style="max-width:150px;">
            <a-popover
                title="商品编号"
                trigger="hover"
            >
              <template slot="content">
                <p>{{row.productNo}}</p>
              </template>
              <a
                  style='color:blue'
                  :style="{'color': row.deleteFlag ? 'gray' : ''}"
                  @click="copy(row.productNo)"
                  type="link"
              >{{row.id}}</a>
              <div class="mt-10 color-blue" @click="userIdSearch(row.userId)">
                <div :style="{'color': row.deleteFlag ? 'gray' : ''}">{{ row.userName }}</div>
                <div :style="{'color': row.deleteFlag ? 'gray' : ''}">【{{ row.userId }}】</div>
              </div>
            </a-popover>

          </div>
        </div>


        <div
            slot="images"
            slot-scope="row"
        >
          <div class="images-box">
            <div
                v-for="(item, index) in row.coverUrls"
                :key="index"
                v-lazy-container="{ selector: 'img' }"
            >
              <img
                  @click="handlePreviewImg(row.coverUrls, index)"
                  style='width:100px;height:100px;'
                  :data-src="item + '/resize,w_200'"
              />
            </div>
          </div>
        </div>
        <!-- 裁剪图片 -->
        <div
            slot="cutImages"
            slot-scope="row"
        >
          <div
              style='max-width:100px;max-height:100px;display:flex;flex-direction: column;justify-content:center;align-items:center;'
          >
            <img
                v-if="row.cutCoverUrl"
                @click="previewImg( [row.cutCoverUrl])"
                style='max-width:100px;max-height:100px;margin-bottom: 6px'
                :src='row.cutCoverUrl || ""'
            />

            <a-button
                size="small"
                :disabled="row.deleteFlag"
                class="mt-10"
                @click="handleGetCutImage(row)"
            >封面图裁剪</a-button>

            <a-popconfirm
                title="取消裁剪封面,用第一张图作为封面"
                ok-text="Yes"
                cancel-text="No"
                :disabled="row.deleteFlag"
                @confirm="deleteImage(row)"
            >
              <a-button
                  v-if="row.cutCoverUrl"
                  size="small" :disabled="row.deleteFlag"
                  class="mt-20"
              >删除</a-button>
            </a-popconfirm>
          </div>
        </div>
        <div slot="description" slot-scope="row">
          <div>
            <p class="font-weight-bold color-black" :style="{'color': row.deleteFlag ? 'gray !important' : ''}">{{ row.productName }}</p>
            <div class="font-weight-bold color-black" :style="{'color': row.deleteFlag ? 'gray !important' : ''}">
              『产品描述』<span class="color-gray">{{ row.context }}</span>
              <div><a-button size="small" :disabled="row.deleteFlag" type="danger" @click="handleChangeEditorProductRemark(row)">修改产品描述</a-button></div>
            </div>
            <p class="font-weight-bold font-size-16 color-blue mt-10" :style="{'color': row.deleteFlag ? 'gray !important' : ''}">尺寸：{{ row.productSize }}</p>
            <p class="font-weight-bold font-size-16 color-red mt-10" :style="{'color': row.deleteFlag ? 'gray !important' : ''}">￥{{row.finalPrice}}</p>
            <p class="font-weight-bold mt-10" :class="fixedPriceCheckStatusClassType[row.reviewStatus]">
              <span :style="{'color': row.deleteFlag ? 'gray !important' : ''}">{{ computedFixedPriceCheckStatusText(row.reviewStatus) }}</span>
              <span class="color-red" :style="{'color': row.deleteFlag ? 'gray !important' : ''}" v-if="row.reviewStatus === -1" >{{ `（${row.reviewDetail ? row.reviewDetail.reviewReason : ''}）` }}</span>
            </p>
            <p class="font-weight-bold color-black mt-10" :style="{'color': row.deleteFlag ? 'gray !important' : ''}" v-if="row.reviewDetail">
              『审核人』<span class="color-gray">{{ row.reviewDetail.reviewAdmin }}</span>
            </p>
          </div>
        </div>
        <div slot="descriptionSlot" slot-scope="row">
          <div class="w-100-w">
            <div
                @click="copy(row.productRemarkVo.coinStateRemark)"
                :style="{'color': !row.deleteFlag ? 'gray !important' : ''}"
            >
              平台定义瑕疵：{{ row.productRemarkVo.coinStateRemark || ' '}}
            </div>
            <div
                @click="copy(row.productRemarkVo.customRemark)"
                :style="{'color': !row.deleteFlag ? 'gray !important' : ''}"
            >
              用户自定义瑕疵：
              <span class="color-red">{{ row.productRemarkVo.customRemark }}</span>
            </div>
            <div
                @click="copy(row.productRemarkVo.platformRemark)"
                :style="{'color': !row.deleteFlag ? 'gray !important' : ''}"
            >
              平台点评：{{ row.productRemarkVo.platformRemark || ' '}}
            </div>
            <div
                @click="copy(row.productRemarkVo.ratingFlawCode)"
                :style="{'color': row.deleteFlag ? 'gray' : 'orange'}"
            >
              评级编码：{{ row.productRemarkVo.ratingFlawCode || ' '}}
            </div>
            <div
                @click="copy(row.productRemarkVo.ratingRemark)"
                :style="{'color': row.deleteFlag ? 'gray' : 'orange'}"
            >
              评级备注：{{ row.productRemarkVo.ratingRemark || ' '}}
            </div>
            <div>
              <a-button
                  size="small"
                  :disabled="row.deleteFlag"
                  style='margin-left:5px'
                  @click="handleEditorProductRemark(row)"
              >编辑产品瑕疵</a-button>
            </div>

          </div>
        </div>
        <div slot="itemActionSlot" slot-scope="row">
          <a-popconfirm
              v-if="row.onShelf === 0"
              title="确认上架吗?"
              ok-text="Yes"
              cancel-text="No"
              :disabled="row.deleteFlag"
              @confirm="handleRacking(row, 1)"
          >
            <a-button :disabled="row.deleteFlag" type="primary" size="small" style='margin-left:5px'>上架</a-button>
          </a-popconfirm>

          <a-popconfirm
              v-if="row.onShelf === 1"
              title="确认下架吗?"
              ok-text="Yes"
              cancel-text="No"
              :disabled="row.deleteFlag"
              @confirm="handleRacking(row, 0)"
          >
            <a-button type="danger" :disabled="row.deleteFlag" size="small" style='margin-left:5px'>下架</a-button>
          </a-popconfirm>
        </div>

<!--    审核    -->
        <div slot="checkProductSlot" slot-scope="row">
          <div class="font-weight-bold color-black" :style="{'color': !row.deleteFlag ? 'gray !important' : ''}" v-if="row.coinKindName">{{ row.coinKindName }}</div>
          <div class="flex-center-center font-weight-bold mt-10" :style="{'color': !row.deleteFlag ? 'gray !important' : ''}" v-if="row.productCode">
            <div class="color-blue" :style="{'color': !row.deleteFlag ? 'gray !important' : ''}">{{computedShowTextComputedRatingCompanyTypeList(row.productRatingCompany)}}</div>
            【<div class="color-blue" :style="{'color': !row.deleteFlag ? 'gray !important' : ''}">{{row.productCode}}</div>】
          </div>
          <div class="flex-center-center flex-wrap mt-10">
            <a-popconfirm
                v-for="item in fixedPriceProductCheckActionBtnTypeList"
                :key="item.value"
                :title="`确定审核为${ item.name }吗？`"
                ok-text="Yes"
                cancel-text="No"
                :disabled="row.deleteFlag"
                @confirm="reviewHandle(row.id,item.value, '')"
            >
              <a-button class="mr-5" :disabled="row.deleteFlag" :type="item.btnType" size="small">{{ item.name }}</a-button>
            </a-popconfirm>
          </div>
          <div class="flex-center-center flex-wrap mt-10">
            <a-button
                v-for="item in fixedPriceProductEditOrRejectActionBtnTypeList"
                :key="item.value"
                class="mr-5"
                :type="item.btnType"
                size="small"
                :disabled="row.deleteFlag"
                @click="handleEditOrRejectBtnShow(item, row)"
            >{{ item.name }}</a-button>
          </div>
        </div>
<!--    状态    -->
        <div slot="itemStatusSlot" slot-scope="row">
          <span style="color:green" :style="{'color': !row.deleteFlag ? 'gray !important' : ''}" v-if="row.onShelf">{{ row.statusName }}</span>
          <span style="color:red" :style="{'color': !row.deleteFlag ? 'gray !important' : ''}" v-else >{{ row.reviewStatus === -1 ? `已驳回（${row.reviewDetail ? row.reviewDetail.reviewReason : ''}）` : row.statusName }}</span>
        </div>
      </a-table>
    </div>
<!--  编辑瑕疵  -->
    <EditorProductRemarkPopup editor-type="fixed" ref="editorProductRemarkPopupEl" @editorSuccess="handleEditorSuccess($event)"/>
<!--  编辑产品描述  -->
    <EditorProductRemark ref="editorProductRemarkEl" @editorSuccess="handleEditorSuccess($event)"/>
<!--  一口价驳回  -->
    <FixedPriceProductRejectPopup ref="fixedPriceProductRejectPopupEl" />
<!--  编辑一口价  -->
    <FixedPriceEditorProductPopup ref="fixedPriceEditorProductPopupEl" @editorSuccess="handleEditorSuccess($event)" />
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import EditorProductRemarkPopup from "@/views/cmsPage/productManage/_components/EditorProductRemarkPopup/index.vue"
import {
  computedFixedPriceCheckStatusListMapText,
  FIXED_PRICE_CHECK_STATUS_CLASS_TYPE, FIXED_PRICE_CHECK_STATUS_LIST,
  PRODUCT_CHECK_ACTION_BTN_TYPE_LIST,
  PRODUCT_EDIT_OR_REJECT_ACTION_BTN_TYPE_LIST,
  tableListColumns
} from "@/views/cmsPage/productManage/fixedPriceProductList/_data"
import FixedPriceProductRejectPopup
  from "@/views/cmsPage/productManage/fixedPriceProductList/_components/FixedPriceProductRejectPopup/index.vue"
import EditorProductRemark
  from "@/views/cmsPage/productManage/fixedPriceProductList/_components/EditorProductRemark/index.vue"
import untils from "@/untils"
import {computedRatingCompanyTypeList, RATING_COMPANY_TYPE_LIST_BY_NEW} from "@/_data"
import FixedPriceEditorProductPopup
  from "@/views/cmsPage/productManage/fixedPriceProductList/_components/FixedPriceEditorProductPopup/index.vue"
import {getBigCoinCategoryTypeListApi, getMangeUserDataListAPi, getUserDataListAPi} from "@/axios/apis"
import {getFixedPriceProductCutImageApi} from "@/views/cmsPage/productManage/fixedPriceProductList/_apis"

export default {
  data() {
    return {
      untils,
      detail: "",
      userDataList: [],
      bigCoinCategoryList: [],
      columns: tableListColumns,
      data: [],
      times: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: { pageNum: 1, pageSize: 10 },
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      loading: false,
      searchTime: [],
      searchParams: {
        userName: "",
        content: "",
        startTime: "",
        endTime: "",
        coinKindName: undefined,
        productKind: "",
        productName: "",
        productCode: undefined,
        productRatingCompany: undefined,
        onShelf: undefined,
        coinCategoryId: undefined,
        reviewAdminId: undefined,
        // onShelf: "",
      },
      fixedPriceCheckStatusClassType: FIXED_PRICE_CHECK_STATUS_CLASS_TYPE,
      fixedPriceProductCheckActionBtnTypeList: PRODUCT_CHECK_ACTION_BTN_TYPE_LIST,
      fixedPriceProductEditOrRejectActionBtnTypeList: PRODUCT_EDIT_OR_REJECT_ACTION_BTN_TYPE_LIST,
      fixedPriceCheckStatusList: FIXED_PRICE_CHECK_STATUS_LIST,
      ratingCompanyTypeList: RATING_COMPANY_TYPE_LIST_BY_NEW,
    };
  },
  components: {
    EditorProductRemarkPopup, EditorProductRemark, FixedPriceProductRejectPopup, FixedPriceEditorProductPopup
  },
  async created() {
    const { userName,productName } = this.$route.query;
    if (userName) {
      this.searchParams.userName = userName;
    }
    if (productName) {
      this.searchParams.productName = productName;
    }
    await this.getData();
    await this.getBigCoinCategoryList()
  },
  mounted() {},
  computed: {
    ...mapState(["ip"]),
    computedFixedPriceCheckStatusText() {
      return (value) => computedFixedPriceCheckStatusListMapText(value)
    },
    computedShowTextComputedRatingCompanyTypeList() {
      return (value) => computedRatingCompanyTypeList(value)
    }
  },
  methods: {
    moment,
    /** 获取裁剪图 */
    async handleGetCutImage(row) {
      this.$loading.show()
      const res = await getFixedPriceProductCutImageApi({ id: row.id })
      this.$loading.hide()
      if (res.status !== '200') return
      await this.getData();
    },
    /** 驳回或编辑 */
    handleEditOrRejectBtnShow(item, row) {
      switch (item.value) {
        case 'edit':
          this.$refs.fixedPriceEditorProductPopupEl.show(row)
          break
        case 'reject':
          this.$refs.fixedPriceProductRejectPopupEl.show(row)
          break
      }
    },
    /** 修改产品描述 */
    handleChangeEditorProductRemark(row) {
      this.$refs.editorProductRemarkEl.show(row)
    },
    /** 删除瑕疵描述 */
    async handleEditorProductRemark(row) {
      this.$refs.editorProductRemarkPopupEl.show({
        id: row.id,
        ...row.productRemarkVo
      })
    },
    /** 编辑瑕疵成功 */
    async handleEditorSuccess() {
      await this.getData()
    },
    /** 原图展示 */
    handlePreviewImg(urls, index) {
      this.$previewImg({
        list: urls.reduce((pre, cur) => {
          const obj = { img_url: cur };
          pre = pre.concat(obj);
          return pre;
        }, []),
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    },
    // 删除裁剪封面图
    async deleteImage(row) {
      const res = await this.axios("/dq_admin/product/cancelCutCover", {
        params: {
          productId: row.id
        }
      });
      if (res.status != 200) return
      this.$message.success(res.message);
      this.getData()
    },

    copy_text(text) {
      let oInput = document.createElement("input");
      oInput.value = text;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.remove();
      this.$message.success("已复制进剪切板");
    },
    copy(text) {
      this.copy_text(text);
    },
    async getData() {
      this.loading = true;
      const res = await this.axios("/dq_admin/product/getOnePriceList", {
        params: {
          ...this.params,
          ...this.searchParams,
        },
      });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
    },
    search() {
      const result = this.times.map((row) => {
        const time = new Date(row._d).toLocaleDateString().replace(/\//g, "-");
        return time;
      });
      if (result && result.length > 0) {
        [this.searchParams.startTime, this.searchParams.endTime] = [...result];
      }
      this.params.pageNum = 1;
      this.getData();
    },
    userIdSearch(id) {
      this.params.pageNum = 1;
      this.searchParams.userName = id;
      this.getData();
    },

    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getData();
    },
    previewImg(urls) {
      if (urls && urls.length > 0) {
        this.$previewImg({
          list: urls.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    // 搜索日期筛选
    searchPickerChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.searchParams.startTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          } else {
            this.searchParams.endTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          }
        });
      } else {
        this.searchParams.startTime = "";
        this.searchParams.endTime = "";
      }
    },
    async handleRacking(row, type) {
      const res = await this.axios.post("/dq_admin/product/racking", {
        productNo: row.productNo,
        type,
      });
      if (res.status === '200') {
        this.$message.success(res.message);
        await this.getData();
      }
    },
    /** 拍品审核，驳回 */
    async reviewHandle(id, reviewStatus, reviewReason){
      const res = await this.axios.post("/dq_admin/product/review", {
        productId: id,
        reviewStatus: reviewStatus,
        reviewReason: reviewReason,
      });
      if (res.status !== '200') return
      this.$message.info(res.message || '成功')
      if (reviewStatus === -1) {
        this.$refs.fixedPriceProductRejectPopupEl.handleCancelReject()
      }
      await this.getData();
    },
    /** 获取大币种分类 */
    async getBigCoinCategoryList() {
      const res = await getBigCoinCategoryTypeListApi()
      if (res.status !== 'SUCCESS') return
      this.bigCoinCategoryList = res.data.records
    },
    /** 获取用户列表 */
    async searchUser(str) {
      const res = await getMangeUserDataListAPi(str)
      if (res.status !== '200') return
      this.userDataList = res.data.records
    }
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
.images-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
</style>