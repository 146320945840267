var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { centered: "", width: 700, title: "批量推送数据" },
      on: { ok: _vm.handleConfirm, cancel: _vm.handleCancel },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "选择大币种" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择大币种",
                        allowClear: ""
                      },
                      on: {
                        search: _vm.searchBigCoinListData,
                        change: _vm.handleChangeBigCoin
                      },
                      model: {
                        value: _vm.forms.coinKindId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "coinKindId", $$v)
                        },
                        expression: "forms.coinKindId"
                      }
                    },
                    _vm._l(_vm.bigCoinListData, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [
                          _vm._v(
                            _vm._s(
                              item.coinKindName +
                                (item.coinCategory
                                  ? "---" + item.coinCategory
                                  : "")
                            )
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择难度等级", prop: "content" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.forms.questionLevel,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "questionLevel", $$v)
                        },
                        expression: "forms.questionLevel"
                      }
                    },
                    _vm._l(_vm.questionLevelList, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("a-form-model-item", { attrs: { label: "图片" } }, [
                _c("div", { staticClass: "images" }, [
                  _c("div", { staticClass: "images-title" }, [
                    _c("div", [_vm._v("正")]),
                    _c("div", [_vm._v("反")])
                  ]),
                  _c("div", { staticClass: "up-image" }, [
                    _c(
                      "div",
                      { staticClass: "up-left" },
                      [
                        _c(
                          "a-upload",
                          {
                            key: _vm.imgKey,
                            attrs: {
                              action: _vm.ip + "/file/upload",
                              "list-type": "picture-card",
                              "file-list": _vm.coverList,
                              multiple: ""
                            },
                            on: {
                              preview: _vm.handlePreview,
                              change: _vm.handleChangeCoverList
                            }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("a-icon", { attrs: { type: "plus" } }),
                                _c("div", { staticClass: "ant-upload-text" }, [
                                  _vm._v("上传图片")
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }